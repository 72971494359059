import { db } from "@/config/firebase";
import {collection, addDoc, serverTimestamp } from "firebase/firestore"

export default {
    namespaced: true,
    state: {
    },
    mutations:{},
    getters:{},
    actions:{
        addContact({commit}, data){
            console.log('data', data);
            const {name, email, msg} = data
            return new Promise(async(resolve, reject)=>{
                try {
                    const docRef = await addDoc(collection(db, "contacts"), {
                        name: name,
                        email: email,
                        msg: msg,
                        timestamp: serverTimestamp(),
                        status: 0
                    });
                    resolve({
                        success: true,
                        data: "Success",
                        res: docRef
                    })
                    commit()
                } catch (error) {
                    reject({
                        success: false,
                        error: error
                    })
                }
                
        
            })
        }
    }
}