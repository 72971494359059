<template>
  <v-footer>
    <v-col
      class="text-center vrij-font"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <b>Vrijraj Singh</b>
    </v-col>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>