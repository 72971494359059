<template>
  <v-list-item
    class="my-2"
    :href="item.link"
    target="_blank"
    :class="$vuetify.theme.dark ? 'list-bg-dark' : 'list-bg-light'"
  >
    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>

      <v-list-item-subtitle>{{
        item.pubDate | dateFilter
      }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            :href="item.link"
            target="_blank"
          >
            <v-icon color="grey lighten-1">mdi-information</v-icon>
          </v-btn>
        </template>
        <!-- {{item.title}} -->
        <span>View more info </span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>
  
  <script>
export default {
  props: ["item"],
  data: () => ({
    dialog: false,
  }),
  filters: {
    dateFilter: (value) => {
      const date = new Date(value);
      return date.toLocaleString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>
  
  <style>
.list-bg-light {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
}
.list-bg-dark {
  border: 1px solid #393939;
  border-radius: 12px;
  background-color: #1e1e1e;
}
</style>