<template>
    <div class="px-0 mx-0">
        <span v-for="(item,i) in data.SocialLinks" :key="i" class="">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn text icon :href="item.link" v-on="on" target="_blank" slot="activator" class="mr-2">
                        <v-icon>{{item.icon}}</v-icon>
                    </v-btn>
                </template>
                <span class="google-font">{{item.name}}</span>
            </v-tooltip>
        </span>
    </div>
</template>

<script>
import data from '@/assets/data/social.json'
    export default{
        data:()=>({
            data:data
        })
    }
</script>