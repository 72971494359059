import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCQRKIGXISgUeuqoGaC_BuilT4eme1vAYE",
  authDomain: "vrij-web.firebaseapp.com",
  databaseURL: "https://vrij-web.firebaseio.com",
  projectId: "vrij-web",
  storageBucket: "vrij-web.appspot.com",
  messagingSenderId: "355936195315",
  appId: "1:355936195315:web:395f88e153c152733d9d86",
  measurementId: "G-PNPDLW5SSK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export {db}