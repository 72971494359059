import { db } from "@/config/firebase";
import { collection, getDocs, getDoc, doc, query, orderBy } from "firebase/firestore"

export default {
    namespaced: true,
    state: {
        talks:[]
    },
    mutations: {
        setTalks:(state, payload)=> state.talks = payload
    },
    getters: {
        getTalks: (state)=> state.talks
    },
    actions: {
        getAllTalks({ commit }) {
            const talks = []
            const dbRef = collection(db, "talks");
            const q = query(dbRef, orderBy("date", "desc"));
            return new Promise((resolve, reject) => {
                getDocs(q).then(querySnapshot=>{
                    querySnapshot.forEach((doc) => {
                        talks.push({ ...doc.data(), ...{ docid: doc.id } });
                    })
                    commit('setTalks', talks)
                    resolve({
                        success: true,
                        data: talks
                    })
                }).catch(error=>{
                    reject({
                        success: false,
                        error: error
                    })
                })
            })
        },

        getTalk(docid){
            return new Promise((resolve, reject)=>{
                const docRef = doc(db, "talks", docid);
                getDoc(docRef).then(docSnap=>{
                    if (docSnap.exists()) {
                        resolve({
                          success: true,
                          data: docSnap.data(),
                        });
                      } else {
                        reject({
                          success: false,
                          error: "Talks Info Not Found",
                        });
                    }
                }).catch(error=>{
                    reject({
                        success: false,
                        error: error
                    })
                })
            })
        }
    }
}