<template>
    <v-dialog v-model="dialog" width="700" style="border-radius: 12px;">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          class="my-2"
          v-bind="attrs"
          v-on="on"
          :class="$vuetify.theme.dark ? 'list-bg-dark' : 'list-bg-light'"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.talktitle }}</v-list-item-title>
  
            <v-list-item-subtitle>{{ item.org.name }}</v-list-item-subtitle>
          </v-list-item-content>
  
          <v-list-item-action>
            <v-btn icon>
              <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
  
      <v-card style="border-radius: 12px;">
        <v-card-title
          class="px-5 py-4 pb-0 vrij-font"
          style="background-position: right bottom"
        >
          <p>{{ item.name }} Details</p>
        </v-card-title>
  
        <v-card-text class="pb-5 pt-0">
            <!-- {{ item }} -->
            <p class="vrij-font mb-0 mt-2" style="font-size: 110%">
          <b>Description</b>
        </p>
        <p class="vrij-font mb-4 mt-0" style="font-size: 110%">
          {{ item.description }}
        </p>
        <p class="vrij-font mb-0 mt-2" style="font-size: 110%"><b>Date</b></p>
        <p class="vrij-font mb-4 mt-0" style="font-size: 110%">
          {{ new Date(item.date) }}
        </p>

        <p class="vrij-font mb-0 mt-2" style="font-size: 110%">
          <b>Event</b>
        </p>
        <p class="vrij-font mb-4 mt-0" style="font-size: 110%">
          <a
            :href="item.event.url"
            target="_blank"
            style="text-decoration: none"
            >{{ item.event.name }}</a
          >
        </p>

        <p class="vrij-font mb-0 mt-2" style="font-size: 110%">
          <b>Organization</b>
        </p>
        <p class="vrij-font mb-4 mt-0" style="font-size: 110%">
          <a
            :href="item.org.url"
            target="_blank"
            style="text-decoration: none"
            >{{ item.org.name }}</a
          >
        </p>
          <!-- <p class="vrij-font mb-0 mt-2" style="font-size: 110%">
            <b>Tech Stacks</b>
          </p>
          <v-chip small class="mr-1" v-for="(tech, index) in item.techstack" :key="index">{{ tech }}</v-chip>
          <br><br>
          <v-btn
            outlined
            v-if="item.demo"
            :href="item.demo"
            rounded
            target="_blank"
            color="teal"
            class="mr-2"
          >
            <v-icon left dark>mdi-information-outline</v-icon>
            See More
          </v-btn> -->
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: ["item"],
    data:()=>({
      dialog: false,
    })
  };
  </script>
  
  <style>
  .list-bg-light {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
  }
  .list-bg-dark {
    border: 1px solid #393939;
    border-radius: 12px;
    background-color: #1e1e1e;
  }
  </style>