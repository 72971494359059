<template>
  <v-main>
    <v-container fluid class="vrij-font">
      <v-row justify="center" align="center">
        <v-col md="10">
          <!-- Hero -->
          <v-container fluid>
            <v-row justify="center" align="center">
              <v-col md="7" cols="12">
                <h1 style="font-weight: 400">Hola!</h1>
                <h1 style="font-weight: 500; color: #5cb55e">I'm Vrijraj</h1>
                <h1 style="font-weight: 500">
                  Google Developer Expert for Web & Firebase
                </h1>
                <h1 style="font-weight: 500">Software Engineer</h1>
                <h1 style="font-weight: 500">UX/UI Designer</h1>
                <p style="font-size: 110%">
                  Always eager to learn new technologies and develop new things
                  based on it.
                </p>
                <SocialMediaVue class="mx-0 px-0 my-4" />
                <v-btn
                  depressed
                  color="green"
                  style="text-transform: capitalize"
                  dark
                  rounded
                  to="/hire-me"
                  >Hire Me</v-btn
                >
                <v-btn
                  depressed
                  outlined
                  color="green"
                  style="text-transform: capitalize"
                  class="mx-4"
                  to="workspace"
                  dark
                  rounded
                  >Explore Workspace</v-btn
                >
              </v-col>
              <v-col md="5">
                <!-- <v-img src="https://musixverse.com/_next/image?url=%2Fassets%2Fhomepage%2FstartFanlight.png&w=3840&q=75"></v-img> -->
              </v-col>
            </v-row>
            <br /><br />
            <v-divider></v-divider>
            <v-row justify="center" align="center" class="my-10">
              <v-col md="12" cols="12" class="text-center">
                <h1 style="font-weight: 500">I'm Working on</h1>
                <p>
                  I strongly value thinking creatively and outside the
                  conventional boundaries. I also believe that learning is a
                  continuous process that persists throughout one's life,
                  regardless of age. Adopting a straightforward and
                  uncomplicated approach to life, while still maintaining
                  ambitious aspirations, is my philosophy. With this mindset, I
                  am actively pursuing my objectives.
                </p>

                <v-container fluid class="text-center">
                  <v-row justify="center" align="center">
                    <v-col md="12">
                      <span v-for="(item, i) in skillsData" :key="i">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              :src="require('@/assets/img/skills/' + item.img)"
                              v-bind="attrs"
                              :width="item.size + 'vh'"
                              v-on="on"
                              contain
                              class="ma-md-4 ma-3"
                            />
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>

            <!-- Experiences -->
            <v-row justify="center" align="center" class="my-10">
              <v-col md="12">
                <h1 style="font-weight: 500">Experiences</h1>
                <h2 style="font-weight: 500">Work Experiences</h2>

                <v-container fluid class="mx-0 px-0">
                  <v-row>
                    <v-col
                      md="3"
                      sm="4"
                      class="mx-0"
                      v-for="(item, index) in expData.WorkExperience"
                      :key="index"
                    >
                      <ExperienceCardVue :item="item" />
                    </v-col>
                  </v-row>
                </v-container>

                <br />
                <h2 style="font-weight: 500">Community Experiences</h2>

                <v-container fluid class="mx-0 px-0">
                  <v-row>
                    <v-col
                      md="3"
                      sm="4"
                      class="mx-0"
                      v-for="(item, index) in expData.CommunityWorkExperience"
                      :key="index"
                    >
                      <ExperienceCardVue :item="item" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Experiences -->

            <!-- Workspace -->
            <v-row justify="center" align="center" class="my-10 d-md-block d-lg-block d-none d-sm-none">
              <v-col md="12">
                <h1 style="font-weight: 500">My Workspace</h1>
                <v-container fluid class="mx-0 px-0">
                  <v-row>
                    <v-col md="4">
                      <p>Recents Projects</p>
                      <template v-if="projects.length">
                        <ProjectCardVue
                          v-for="(project, index) in projects"
                          :item="project"
                          :key="index"
                        />
                      </template>
                      <v-skeleton-loader
                        type="list-item-two-line"
                        v-else
                      ></v-skeleton-loader>
                    </v-col>
                    <v-col md="4">
                      <p>Recents Talks</p>
                      <template v-if="talks.length">
                        <TalkCard
                          v-for="(talk, index) in talks"
                          :item="talk"
                          :key="index"
                        />
                      </template>
                      <v-skeleton-loader
                        type="list-item-two-line"
                        v-else
                      ></v-skeleton-loader>
                    </v-col>
                    <v-col md="4">
                      <p>Recents Blogs</p>
                      <template v-if="blogs.length">
                        <BlogCardVue
                          v-for="(blog, index) in blogs"
                          :item="blog"
                          :key="index"
                        />
                      </template>
                      <v-skeleton-loader
                        type="list-item-two-line"
                        v-else
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Workspace -->
          </v-container>
          <!-- Hero -->
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import skillsDataFromJSON from "./../assets/data/skills.json";
import experienceData from "./../assets/data/experience.json";
import SocialMediaVue from "@/components/common/SocialMedia.vue";
import ExperienceCardVue from "@/components/common/ExperienceCard.vue";
import ProjectCardVue from "@/components/common/ProjectCard.vue";
import TalkCard from "@/components/common/TalkCard.vue";
import BlogCardVue from "@/components/common/BlogCard.vue";

export default {
  name: "HomePage",
  data: () => ({
    skillsData: [],
    expData: [],
    projects: [],
    talks: [],
    blogs: [],
  }),
  created(){
    document.title = "Home | Vrijraj Singh"
  },
  mounted() {
    this.skillsData = skillsDataFromJSON;
    this.expData = experienceData;
    this.getAllProjects();
    this.getAllTalks();
    this.getAllBlogs();
  },
  components: {
    SocialMediaVue,
    ExperienceCardVue,
    ProjectCardVue,
    TalkCard,
    BlogCardVue,
  },
  methods: {
    async getAllProjects() {
      try {
        let res = await this.$store.dispatch("projectStore/getAllProjects");
        if (res.success) {
          this.projects = res.data.slice(0, 4);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllTalks() {
      try {
        let res = await this.$store.dispatch("talkStore/getAllTalks");
        if (res.success) {
          this.talks = res.data.slice(0, 4);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllBlogs() {
      try {
        let res = await this.$store.dispatch("blogStore/getAllBlogs");
        if (res.success) {
          this.blogs = res.data.items.slice(0, 4);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
