import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/workspace',
    name: 'workspace',
    component: () => import(/* webpackChunkName: "workspace" */ '../views/WorkspacePage.vue')
  },
  {
    path: '/hire-me',
    name: 'hire-me',
    component: () => import(/* webpackChunkName: "hire-me" */ '../views/HireMePage.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue')
  },
  { 
    path: "*", 
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "not-found-page" */ '../views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
