<template>
<!--  -->
  <v-app-bar
    app
    flat
    fixed
    class="px-2 mx-6 mt-5 mt-md-3 mx-md-auto"
    style="
      align-items: center;
      max-width: 1024px;
      margin: auto;
      border-radius: 20px;
      -webkit-backdrop-filter: saturate(100%) blur(5px);
      backdrop-filter: saturate(100%) blur(10px);
      background-color: #E9F1FB;
    "
    :style="
      $vuetify.theme.dark
        ? 'border: 1px solid #424242; background-color: rgba(255, 255, 255, 0.03)'
        : 'border: 1px solid #dadce0 !important;background-color: rgba(255, 255, 255, 0.6)'
    "
  >
    <v-app-bar-nav-icon class="d-md-none d-lg-none" @click="ToggelToobar"></v-app-bar-nav-icon>

    <v-toolbar-title style="width: 200px;" class="vrij-font px-0" >
      <router-link
        to="/"
        class="google-font"
        style="text-decoration: none; font-size: 100%"
        :class="
          $vuetify.theme.dark
            ? 'grey--text text--lighten-5'
            : 'grey--text text--darken-2'
        "
      >
        Vrijraj Singh
      </router-link>
    </v-toolbar-title>

    <v-tabs
      :color="$vuetify.theme.dark ? 'green' : 'black'"
      :slider-color="$vuetify.theme.dark ? 'green' : 'black'"
      class="hidden-sm-and-down vrij-font"
    >
      <v-tab
        class="vrij-font"
        aria-label="toolbar links"
        v-for="(link, i) in links.filter((obj) => obj.meta.showToolbar)"
        :key="i"
        :to="link.to"
        @click="onClick($event, link)"
        style="text-transform: capitalize; font-size: 100%;font-weight: 400;"
        >{{ link.text }}</v-tab
      >
    </v-tabs>

    <v-spacer></v-spacer>
    <!-- :hide-slider="getRouteName()" :hide-slider="getRouteName()" -->
    
    <!-- <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
    <v-btn small depressed color="green" style="text-transform: capitalize" dark rounded href="https://gradus.vrijraj.xyz/" target="_blank">
      Codelab <v-icon right x-small>mdi-open-in-new</v-icon>
    </v-btn>

    <v-btn icon v-on:click="changeTheme" class="ml-2" aria-label="theme btn">
      <v-icon v-if="this.$vuetify.theme.dark">mdi-brightness-7</v-icon>
      <v-icon v-else>mdi-brightness-4</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppToolbarComponent",
  data: () => ({}),
  computed:{
    links(){
        return this.$store.getters.links
    }
  },
  methods: {
    ToggelToobar(){
      this.$store.commit('toggleDrawer')
    },
    onClick(e, item) {
      e.stopPropagation();
      if (item.to || !item.href) return;
      this.$vuetify.goTo(item.href);
    },
    changeTheme() {
      let metaThemeColor = document.querySelector("meta[name=theme-color]");
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (localStorage)
        localStorage.setItem("darkMode", this.$vuetify.theme.dark);
      if (this.$vuetify.theme.dark) {
        metaThemeColor.setAttribute("content", "#212121");
      } else {
        metaThemeColor.setAttribute("content", "#3F51B5");
      }
    },
  },
};
</script>

<style>
@media only screen and (min-width: 600px) {
  .v-toolbar .v-toolbar__content {
    padding-left: 3%;
    padding-right: 3%;
  }
}
</style>