<template>
  <v-app :class="$vuetify.theme.dark ? 'darkBackground' : 'lightBackground'">
    <AppToolbarVue />
    <AppDrawerVue />
    <v-main>
      <v-theme-provider root>
        <v-slide-y-reverse-transition>
          <router-view v-show="show" />
        </v-slide-y-reverse-transition>
      </v-theme-provider>
    </v-main>
    <AppFooterVue/>
  </v-app>
</template>

<script>
import AppDrawerVue from "./components/core/AppDrawer.vue";
import AppFooterVue from './components/core/AppFooter.vue';
import AppToolbarVue from "./components/core/AppToolbar.vue";

export default {
  name: "App",
  components: {
    AppToolbarVue,
    AppDrawerVue,
    AppFooterVue
  },
  data: () => ({
    show: false,
  }),
  created() {
    this.show = true;
  },
  beforeCreate() {
    localStorage.getItem("darkMode") == "true"
      ? (this.$vuetify.theme.dark = true)
      : (this.$vuetify.theme.dark = false);
  },
};
</script>

<style>
.darkBackground {
  background-color: black;
}
.lightBackground {
  background-color: #F5F8FC !important;
  /* background-color: white; */
}
</style>
