import { db } from "@/config/firebase";
import { collection, getDocs, getDoc, doc, query, orderBy } from "firebase/firestore"

export default {
    namespaced: true,
    state: {
        projects:[]
    },
    mutations: {
        setProjects:(state, payload)=> state.projects = payload
    },
    getters: {
        getProjects: (state)=> state.projects
    },
    actions: {
        getAllProjects({ commit }) {
            const projects = []
            const dbRef = collection(db, "projects");
            const q = query(dbRef, orderBy("timestamp", "desc"));
            return new Promise((resolve, reject) => {
                getDocs(q).then(querySnapshot=>{
                    querySnapshot.forEach((doc) => {
                        projects.push({ ...doc.data(), ...{ docid: doc.id } });
                    })
                    commit('setProjects', projects)
                    resolve({
                        success: true,
                        data: projects
                    })
                }).catch(error=>{
                    reject({
                        success: false,
                        error: error
                    })
                })
            })
        },

        getProject(docid){
            return new Promise((resolve, reject)=>{
                const docRef = doc(db, "projects", docid);
                getDoc(docRef).then(docSnap=>{
                    if (docSnap.exists()) {
                        resolve({
                          success: true,
                          data: docSnap.data(),
                        });
                      } else {
                        reject({
                          success: false,
                          error: "Project Info Not Found",
                        });
                    }
                }).catch(error=>{
                    reject({
                        success: false,
                        error: error
                    })
                })
            })
        }
    }
}