<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-card v-on="on" outlined style="border-radius: 12px" class="py-3">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="mb-4 vrij-font mb-4" style="font-size: 15px">
              {{ item.duration }}
            </div>
            <v-list-item-title class="vrij-font mb-1" style="font-size: 110%">{{
              item.title
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="vrij-font mb-1"
              style="font-size: 80%"
              >{{ item.company }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-avatar class="ma-2" size="60" contain eager>
            <v-img :src="getImgUrl(item.avatar)"></v-img>
          </v-avatar>
        </v-list-item>
      </v-card>
    </template>
    <v-card
      v-if="dialog"
      style="
        border-radius: 12px;
        /* -webkit-backdrop-filter: saturate(100%) blur(10px);
        backdrop-filter: saturate(100%) blur(10px); */
      "
      
    >
    <!-- :style="
        $vuetify.theme.dark
          ? 'border: 1px solid #424242; background-color: rgba(1, 251, 55, .00005)'
          : 'border: 1px solid #dadce0 !important;background-color: rgba(255, 255, 255, 0.6)'
      " -->
      <v-card-title
        class="px-5 py-4 pb-0 vrij-font"
        style="background-position: right bottom"
      >
        <p>{{ item.title }} | {{ item.company }}</p>
      </v-card-title>
      <v-card-text class="pb-5 pt-0">
        <!-- <v-divider></v-divider> -->
        <p class="vrij-font my-0 my-1" style="font-size: 110%">
          <b>{{ item.duration }}</b>
        </p>
        <p class="vrij-font my-0 my-1" style="font-size: 110%">
          <b>{{ item.location }}</b>
        </p>
        <p class="vrij-font my-0 mb-2 mt-3" style="font-size: 120%">
          <b>Description</b>
        </p>
        <ul v-for="(itemp, j) in item.des" :key="j">
          <li class="vrij-font mb-1" style="font-size: 110%">
            {{ itemp.data }}
          </li>
        </ul>
        <br />
        <v-btn
          v-if="item.links.CompanyWebsite"
          outlined
          color="green"
          rounded
          :href="item.links.CompanyWebsite"
          target="_blank"
          class="mr-2"
          >Company Website</v-btn
        >

        <v-btn
          outlined
          v-if="item.links.SeeMoreLink"
          :href="item.links.SeeMoreLink"
          target="_blank"
          color="green"
          rounded
          class="mr-2"
          >See More</v-btn
        >
      </v-card-text>
      <v-card-actions
      >
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    item: {},
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    getImgUrl(pic) {
      if (pic.length > 0) {
        return require("@/assets/img/company/" + pic);
      } else {
        // return require('@/assets/img/avatar.png')
      }
    },
  },
};
</script>