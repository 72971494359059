<template>
  <!-- :color="$vuetify.theme.dark ? '' : 'white'" -->
  <v-navigation-drawer
    clipped
    :style="
      $vuetify.theme.dark
        ? 'border: 1px solid #424242; background-color: rgba(255, 255, 255, 0.03)'
        : 'border: 1px solid #dadce0 !important;background-color: rgba(255, 255, 255, 0.6)'
    "
    floating
    app
    width="300px"
    v-model="drawer"
    temporary
    style="-webkit-backdrop-filter: saturate(100%) blur(5px);
      backdrop-filter: saturate(100%) blur(10px);"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="google-font py-1" style="font-size: 130%">
          Vrijraj Singh
        </v-list-item-title>
        <!-- <v-list-item-subtitle>
            By GDG WTM India
        </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-list dense nav rounded>
      <v-list-item
        v-for="(link, i) in links.filter((obj) => obj.meta.showDrawer)"
        :key="i"
        :to="link.to"
        :href="link.href"
        @click="onClick($event, link)"
        class="google-font my-0 py-0"
        color="green"
      >
        <v-list-item-icon>
          <v-icon v-text="link.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="link.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
  
  <script>
export default {
  name: "AppDrawer",
  computed: {
    links: {
      get() {
        return this.$store.getters["links"];
      },
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      },
    },
  },
  methods: {
    onClick(e, item) {
      e.stopPropagation();
      if (item.to === "/") {
        this.$vuetify.goTo(0);
        // this.setDrawer(false);
        // this.$store.dispatch("setDrawer", false);
        return;
      }
      if (item.to || !item.href) return;
      this.$vuetify.goTo(item.href);
      this.$store.dispatch("setDrawer", false);
      // this.setDrawer(false);
    },
  },
};
</script>
  
  <style>
</style>