import Vue from 'vue'
import Vuex from 'vuex'
import projectStore from './modules/project.store'
import talkStore from './modules/talk.store'
import blogStore from './modules/blog.store'
import contactStore from './modules/contact.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    items: [
      {
        text: "Home",
        to: "/",
        icon: "mdi-home-outline",
        meta: {
          showToolbar: true,
          showBottomNav: true,
          showDrawer: true
        },
      },
      {
        text: "Workspace",
        to: "/workspace",
        icon: "mdi-format-float-left",
        meta: {
          showToolbar: true,
          showBottomNav: true,
          showDrawer: true
        },
      },
      {
        text: "Hire Me",
        to: "/hire-me",
        icon: "mdi-account-switch",
        meta: {
          showToolbar: true,
          showBottomNav: true,
          showDrawer: true
        },
      },
      {
        text: "About me",
        to: "/about",
        icon: "mdi-account-multiple",
        meta: {
          showToolbar: true,
          showBottomNav: false,
          showDrawer: true
        },
      }
    ],
  },
  getters: {
    links: (state) => state.items,
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),
  },
  actions: {
  },
  modules: {
    projectStore,
    talkStore,
    blogStore,
    contactStore
  }
})
