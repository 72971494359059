export default {
    namespaced: true,
    state: {
    },
    mutations:{},
    getters:{},
    actions:{
        getAllBlogs(){
            return new Promise((resolve, reject)=>{
                fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@vrijrajsingh').then(res=>res.json()).then(data=>{
                    resolve({
                        success: true,
                        data: data
                    })
                }).catch(error=>{
                    reject({
                        success: false,
                        error: error
                    })
                })
            })
        }
    }
}